import React from "react";
import style from "./mobileMenuBtn.module.scss";
import UiBtn from "../../ui/btn/uiBtn"

export const MobileMenuBtn = () => {

  return (
    <div className={style.mobileMenu__btn}>
      <UiBtn className={style.mobileMenu__btnBook} href="https://acuboston.janeapp.com/#/discipline/1/treatment/1" target="_blank" rel='noopener noreferrer'>
        BOOK ONLINE
      </UiBtn>
      <UiBtn className={style.mobileMenu__btnTelemedicine} href="https://acuboston.janeapp.com/#/discipline/2/treatment/9" target="_blank" rel='noopener noreferrer'>
        TELEMEDICINE
      </UiBtn>
    </div>
  )
}