import React, { ReactElement } from 'react';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import style from './uiBtn.module.scss';

const UiBtn = (props: {
  className: string,
  children: string | ReactElement,
  href: string,
  name?: string,
  target?: string,
  rel?: string
}) => {

  const classes = props.className;

  return (
    <a
      className={`${style.btn} ${classes}`}
      href={props.href}
      target={props.target}
      onClick={ (e) => console.log('log gtag', props.name,window.gtag('event','page_view',{'page_title': props.name})) }
      rel={props.rel}
    >
      {props.children}
    </a>
  );
};

export default UiBtn;