import React from "react"
import styles from "./menuNav.module.scss"
import { Link } from "gatsby"

function MenuNavBar() {

  return (
    <nav className={styles.headerNav}>
      <ul className={styles.headerNav__menu}>
        <li className={styles.headerNav__menuItem}>
          <Link className={styles.headerNav__link} onClick={ (e) =>window.gtag('event','page_view',{'page_title': 'top'}) } to='#top'>HOME</Link>
        </li>

        <li className={styles.headerNav__menuItem}>
          <Link to="#acupuncture" onClick={ (e) =>window.gtag('event','page_view',{'page_title': 'acupuncture'}) } className={styles.headerNav__link}>ACUPUNCTURE</Link>
        </li>

        <li className={styles.headerNav__menuItem}>
          <Link to="#herbal" onClick={ (e) =>window.gtag('event', 'page_view',{'page_title': 'herbal'}) } className={styles.headerNav__link}>HERBAL MEDICINE</Link>
        </li>

        <li className={`${styles.headerNav__menuItem} ${styles.hamburgerOnly}`}>
          <Link to="#community-acupuncture" onClick={ (e) =>window.gtag('event','page_view',{'page_title': 'community-acupuncture'}) } className={styles.headerNav__link}>COMMUNITY ACUPUNCTURE</Link>
        </li>

        <li className={styles.headerNav__menuItem}>
          <Link to="#sauna" onClick={ (e) =>window.gtag('event','page_view',{'page_title': 'infrared sauna'}) } className={styles.headerNav__link}>INFRARED SAUNA</Link>
        </li>

        <li className={styles.headerNav__menuItem}>
          <Link to="#insurance" onClick={ (e) =>window.gtag('event', 'page_view',{'page_title': 'insurance'}) } className={styles.headerNav__link}>PRICING & INSURANCE</Link>
        </li>

        <li className={`${styles.headerNav__menuItem} ${styles.hamburgerOnly}`}>
          <Link to="#mailchimp" onClick={ (e) =>window.gtag('event','page_view',{'page_title': 'mailchimp'}) } className={styles.headerNav__link}>NEWSLETTER</Link>
        </li>

        <li className={styles.headerNav__menuItem}>
          <Link to="#contacts" onClick={ (e) =>window.gtag('event', 'page_view',{'page_title': 'contacts'}) } className={styles.headerNav__link}>CONTACT</Link>
        </li>
      </ul>
    </nav>
  )
}

export default MenuNavBar